import styled, { css } from 'styled-components';

import theme from '../../../styles/config/theme';
import { divide } from '../../../styles/libs/calculate';

const sizes = {
  xs: theme.PADDING_XS,
  small: theme.PADDING_SMALL,
  medium: theme.PADDING_MEDIUM,
  large: theme.PADDING_LARGE,
};

export default styled.div`
  margin: 0 -${({ size }) => divide(sizes[size || 'medium'], 2)};
  ${({ marginLess }) => marginLess && css`
    margin: 0;
  `}
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'nowrap')};
  ${({ gap }) => gap && `gap: ${gap};`}
  width: 100%;
  ${({ align }) => align && css`
    align-items: ${align};
  `}
  ${({ justify }) => justify && css`
    justify-content: ${justify};
  `}
  ${({ flexDirection }) => flexDirection && css`
    flex-direction: ${flexDirection};
  `}
`;
