const products = {
  CAMC_MEMBER_PRODUCT: 1,
  CAMC_UK_PRODUCT: 2,
  CAMC_EF_PRODUCT: 3,
};

export const BASKET_STATES = {
  NEUTRAL: 0,
  UK: 1,
  OVERSEAS: 2,
};

export const getProductId = (productString) => {
  const concatenatedProduct = productString?.split(' ')?.join('_')?.toUpperCase();
  return products?.[concatenatedProduct];
};

export default products;
