export const lettersMoreThenThree = /(([a-zA-Z]{2,})|([' .-]{0,1}))[a-zA-Z]{1,}$/;

export const date = /^(\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)[0-3][0-9]|(3)[0-1])$/;

export const lettersAndNumbersMoreThenFive = /^([a-zA-Z ]|[0-9 ]){1,}$/;

export const numbersAndBackSlash = /^([0-9]|[/]){0,10}$/;

export const dateWithHyphons = /^(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))$/;
// eslint-disable-next-line
export const dateInputValidation = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;

// Number field validation
export const hasMoreThanTwoDecimalsRegex = /^\d+\.\d{3,}$/;
export const extractTwoDecimalsRegex = /^\d+\.\d{2}/;
export const numericCharactersRegex = /[\d.]/;
