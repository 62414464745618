import React from 'react';
import PropTypes from 'prop-types';

import StyledRow, { Flex } from './Row.style';

const propTypes = {
  align: PropTypes.string,
  justify: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  marginLess: PropTypes.bool,
  rowRef: PropTypes.shape({}),
  size: PropTypes.string,
  wrap: PropTypes.bool,
  flexDirection: PropTypes.string,
  gap: PropTypes.string,
};

const defaultProps = {
  align: '',
  justify: '',
  className: '',
  marginLess: false,
  rowRef: null,
  size: 'medium',
  wrap: true,
  flexDirection: '',
  gap: '',
};

function Row({
  align, children, className, rowRef, size, wrap, justify, marginLess, flexDirection, gap,
}) {
  if (!children) return null;

  return (
    <StyledRow className={className} size={size} ref={rowRef} marginLess={marginLess}>
      <Flex align={align} flexWrap={wrap} justify={justify} flexDirection={flexDirection} gap={gap}>
        {React.Children.map(children, child => (
          child && React.cloneElement(child, { size })
        ))}
      </Flex>
    </StyledRow>
  );
}

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
