import gql from 'graphql-tag';

export default gql`
  query Dictionary {
    dictionary @rest(
      type: "DictionaryItem",
      path: "Dictionary"
    ) {
      key
      value
    }
  }
`;
