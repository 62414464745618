import styled, { css } from 'styled-components';

import theme from '../../../styles/config/theme';
import { divide } from '../../../styles/libs/calculate';

const sizes = {
  xs: theme.PADDING_XS,
  small: theme.PADDING_SMALL,
  medium: theme.PADDING_MEDIUM,
  large: theme.PADDING_LARGE,
};

export default styled.div`
  ${({ align }) => align && css`
    text-align: ${align};
  `}
  ${({ flexbox }) => flexbox && css`
    display: flex;
  `}
  ${({ justifyContent }) => justifyContent && css`
    justify-content: ${justifyContent};
  `}
  flex: ${({ col, flex }) => (col ? `0 0 ${(100 / 12) * col}%` : flex)};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  min-width: 0;
  padding: 0 ${({ size }) => divide(sizes[size || 'medium'], 2)};
  width: ${props => props.width};
`;
