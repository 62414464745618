export const pound = '\u00A3';

export default (price, symbol = pound, hidePence) => {
  if (!price) return `${symbol}0.00`;
  let result = parseFloat(price * (price < 0 ? -1 : 1));
  if (!hidePence) {
    result = result.toFixed(2);
  } else {
    result = Math.floor(result);
  }

  return `${price < 0 ? '-' : ''}${symbol}${result}`;
};
