import React, { memo } from 'react';
import PropTypes from 'prop-types';

import StyledCol from './Col.style';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
      PropTypes.string,
    ])),
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  col: PropTypes.number,
  flex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  flexbox: PropTypes.bool,
  size: PropTypes.string,
  width: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  flexDirection: undefined,
  justifyContent: undefined,
  col: null,
  className: '',
  flex: 1,
  flexbox: false,
  size: 'medium',
  width: null,
};

function Col({ children, ...props }) {
  return (
    <StyledCol
      {...props}
    >
      {children}
    </StyledCol>
  );
}

Col.propTypes = propTypes;
Col.defaultProps = defaultProps;

export default memo(Col);
